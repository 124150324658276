<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
    </div>
</template>
<script>
// 产品-绿洲
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
}
</style>